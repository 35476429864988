/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components), {Link} = _components;
  if (!Link) _missingMdxReference("Link", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Set up tents"), "\n", React.createElement(_components.p, null, "You join James and Maxine in setting up the tents while Halley walks off into the woods to gather firewood."), "\n", React.createElement(_components.p, null, "Maxine and James are pitching a tent for Maxine and Halley, while you focus on building up a tent for yourself and James."), "\n", React.createElement(_components.p, null, "After the tents are up and you've built a firepit from stones, Halley emerges from the woods with a handful of branches - pale as a ghost. You ask if she's okay and she tells you there's something cursed about the forrest. It's like there's faces in the shadows that stalk you. You jokingly ask if she let the scary podcasts get to her and help her place the branches in the firepit."), "\n", React.createElement(_components.p, null, "While you're sitting around the fire making up grand stories about the mysteries you will discover in these woods, Halley keeps glancing around the edges of the forrest. Maxine and James somewhat successfully calm her down."), "\n", React.createElement(Link, {
    to: "../first-night"
  }, "> Go to sleep"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
